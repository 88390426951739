<template>
  <div class="MyAccountView">
    <div class="o-container-gray">
      <h3 class="o-default-h3">{{ $t('myAccountView.title') }}</h3>
      <!-- @TODO - To resolve issue with "Overview" tab remaining highlighted if a CardButton is clicked -->
      <!-- <md-tabs md-sync-route @click="startMyAccountLoader()"> -->
      <md-tabs md-sync-route md-dynamic-height @click="startMyAccountLoader()">
        <md-tab v-for="tab in this.tabs"
                :key="tab.id"
                :id="tab.id"
                :md-label="tab.text"
                :md-icon="tab.icon"
                :to="tab.route"
                exact>
          <router-view v-if="isChildRoute(routePath, tab.route)"></router-view>
        </md-tab>
      </md-tabs>
    </div>
  </div> <!-- /.MyAccountView -->
</template>

<script>
import {
  START_MY_ACCOUNT_LOADER
} from '@/store/actions.type';

export default {
  name: 'MyAccountView',
  data() {
    return {
      tabs: [
        {
          id: 'tab-overview',
          route: '/my-account',
          icon: 'view_compact',
          text: this.$t('myAccountView.overview'),
        },
        {
          id: 'tab-user-details',
          route: '/my-account/user-details',
          icon: 'account_box',
          text: this.$t('myAccountView.accountDetails'),
        },
        {
          id: 'tab-billing-details',
          route: '/my-account/billing-details',
          icon: 'account_balance_wallet',
          text: this.$t('myAccountView.billingDetails'),
        },
        {
          id: 'tab-user-auctions',
          route: '/my-account/user-auctions',
          icon: 'gavel',
          text: this.$t('myAccountView.myAuctions'),
        },
        {
          id: 'tab-user-domains',
          route: '/my-account/user-domains',
          icon: 'domain_verification',
          text: this.$t('myAccountView.myDomains'),
        },
        {
          id: 'tab-account-security',
          route: '/my-account/account-security',
          icon: 'security',
          text: this.$t('myAccountView.accountSecurity'),
        },
        {
          id: 'tab-account-settings',
          route: '/my-account/account-settings',
          icon: 'settings',
          text: this.$t('myAccountView.accountSettings'),
        },
      ],
      routePath: null
    }
  },
  watch: {
    '$route.path': 'currentRoute'
  },
  mounted() {
    this.currentRoute();
  },
  methods: {
    currentRoute() {
      this.routePath = this.$route.path;
    },
    startMyAccountLoader() {
      console.log('My Account Loader Started') // @todo MyAccount tabs loader start
      this.$store.dispatch(START_MY_ACCOUNT_LOADER);
    },
    isChildRoute(routePath, tabRoute) {
      if (routePath) {
        return routePath.indexOf(tabRoute) !== -1;
      }
    }
  }
};
</script>

<style lang="scss">
.MyAccountView {

  .md-tabs-navigation {
    justify-content: space-between !important;
    box-shadow: 0 .2rem .1rem -.1rem rgba(0, 0, 0, .2), 0 .1rem .1rem 0 rgba(0, 0, 0, .14), 0 .1rem .3rem 0 rgba(0, 0, 0, .12);

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      border-radius: .6rem;
      height: .6rem;
      width: .6rem;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: .6rem;
      background: #757575;
    }

    &::-webkit-scrollbar-corner,
    &::-webkit-scrollbar-track {
      background: #e1e1e1;
    }
  }

  .md-tabs-content {
    background-color: transparent;
    margin-left: -.4rem;
    margin-right: -.4rem;
  }

  .md-tab {
    padding-left: .4rem;
    padding-right: .4rem;
  }

  .loader-container {
    @include flex-center-xy;
    min-height: 20rem;
  }
}
</style>
